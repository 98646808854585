import {
  definedExternals,
  providedExternals,
} from './feature-hub/provided-externals';
// eslint-disable-next-line sort-imports
import './runtime-public-path';
import {
  FeatureAppManager,
  FeatureServices,
  Logger,
  createFeatureHub,
} from '@feature-hub/core';

import {
  SerializedStateManagerV1,
  serializedStateManagerDefinition,
} from '@feature-hub/serialized-state-manager';

import { defineExternals, loadAmdModule } from '@feature-hub/module-loader-amd';
import { AuthServiceProviderV2 } from '@volkswagen-onehub/audi-auth-service/dist/types/v2/AuthServiceProviderV2';
import { ContentStore } from '@volkswagen-onehub/audi-content-service';
import { DebugListener } from './debug-extension/debug-listener';
import { FeatureAppIntegrator } from './feature-apps/feature-app-integrator';
import { HistoryServiceV2 } from '@feature-hub/history-service';
import { activateHistoryServiceForNavigation } from './feature-services/feature-service-history';

import { createLogger } from './logger';
import { featureServiceDefinitions } from './feature-hub/service-definitions';
import { featureServiceDependencies } from './feature-hub/service-dependencies';
import { processAuthService } from './feature-services/feature-service-auth';
import { processSerializedStates } from './feature-services/feature-service-serialized-states';

import { setWebpackPublicPath } from './runtime-public-path';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  interface StateRegistry {
    addStore: (
      arg0: string,
      arg1: Record<string, unknown>,
      arg2: Record<string, unknown>,
    ) => void;
  }
  interface Microkernel {
    stateRegistry: StateRegistry;
  }
  interface Window {
    microkernel: Microkernel;
  }
}

const PAGE_LOADED = 'PAGE_LOADED';
const LAYER_LOADED = 'LAYER_LOADED';
const CONTENT_RENDERED = 'content:rendered';

const __setupIntegrator = async (): Promise<{
  contentStore: ContentStore;
  featureAppManager: FeatureAppManager;
  featureServices: FeatureServices;
  logger: Logger;
}> => {
  setWebpackPublicPath();
  defineExternals(await definedExternals());

  const contentStore = new ContentStore();
  const logger = createLogger();

  const { featureAppManager, featureServices } = createFeatureHub(
    'audi:integrator:csr',
    {
      featureServiceDefinitions: await featureServiceDefinitions(
        contentStore,
        logger,
      ),
      featureServiceDependencies: featureServiceDependencies,
      logger,
      moduleLoader: loadAmdModule,
      providedExternals,
    },
  );

  // Initialize debug extension //
  new DebugListener(featureAppManager, featureServices);
  //  //

  return {
    contentStore,
    featureAppManager,
    featureServices,
    logger,
  };
};

const __processFeatureServices = async (
  featureServices: FeatureServices,
): Promise<void> => {
  const serializedStateManager = featureServices[
    serializedStateManagerDefinition.id
  ] as SerializedStateManagerV1;
  processSerializedStates(serializedStateManager);

  const authServiceProvider = featureServices[
    'vw:authService'
  ] as AuthServiceProviderV2;
  const authService = authServiceProvider.register('myaudi');
  processAuthService(authService);

  const historyService = featureServices['s2:history'] as HistoryServiceV2;
  activateHistoryServiceForNavigation(historyService);
};

const __applyFeatureHub = async (
  event: CustomEvent,
  featureAppIntegrator: FeatureAppIntegrator,
): Promise<void> => {
  if (
    event &&
    event.type &&
    (event.type === PAGE_LOADED ||
      event.type === LAYER_LOADED ||
      event.type === CONTENT_RENDERED)
  ) {
    if (event.detail && event.detail.element) {
      await featureAppIntegrator.initFeatureApps(event.detail.element);
    }
  }
};

const __integrateFeatureApps = async (): Promise<void> => {
  const { contentStore, featureAppManager, featureServices } =
    await exportedObject.__setupIntegrator();

  await exportedObject.__processFeatureServices(featureServices);

  const featureAppIntegrator = new FeatureAppIntegrator(
    featureAppManager,
    contentStore,
  );

  const applyFeatureHubToPartial = (event): void => {
    exportedObject.__applyFeatureHub(event, featureAppIntegrator);
  };

  await featureAppIntegrator.initFeatureApps(document.body);

  document.addEventListener(PAGE_LOADED, applyFeatureHubToPartial);
  document.addEventListener(LAYER_LOADED, applyFeatureHubToPartial);
  document.addEventListener(CONTENT_RENDERED, applyFeatureHubToPartial);
};

const exportedObject = {
  __applyFeatureHub,
  __integrateFeatureApps,
  __processFeatureServices,
  __setupIntegrator,
};

exportedObject.__integrateFeatureApps().catch(console.error);

export default exportedObject;
