import * as React from 'react';

import {
  Css,
  FeatureAppLoader,
  FeatureHubContextProvider,
} from '@feature-hub/react';

import {
  FeatureAppEnvironment,
  FeatureAppManager,
  FeatureServices,
} from '@feature-hub/core';

import { ITrackingServiceV1 } from '@volkswagen-onehub/audi-tracking-service';

interface FeatureAppAttributes {
  readonly baseUrl: string;
  readonly callbacks: string;
  readonly config: Record<string, unknown>;
  readonly css: Css[];
  readonly featureAppId: string;
  readonly serverSrc: string;
  readonly src: string;
}

const beforeCreateInitTracking = (
  env: FeatureAppEnvironment<FeatureServices, Record<string, unknown>>,
): void => {
  const trackingService = env.featureServices[
    'audi-tracking-service'
  ] as ITrackingServiceV1;
  if (trackingService) {
    trackingService.featureAppId = env.featureAppId;
  }
};

async function hydrateFeatureApp(
  featureApp: Element,
  featureAppAttributes: FeatureAppAttributes,
  featureAppManager: FeatureAppManager,
): Promise<void> {
  const { baseUrl, config, css, featureAppId, serverSrc, src } =
    featureAppAttributes;

  const { ReactDom } = await import(
    /* webpackChunkName: "react-dom" */ '../externals/react-dom'
  );

  ReactDom.hydrate(
    <FeatureHubContextProvider value={{ featureAppManager }}>
      <FeatureAppLoader
        {...(baseUrl && { baseUrl: baseUrl })}
        {...(config && { config: config })}
        {...(css && { css: css })}
        {...(featureAppId && { featureAppId: featureAppId })}
        {...(serverSrc && { serverSrc: serverSrc })}
        {...(src && { src: src })}
        beforeCreate={beforeCreateInitTracking}
      />
    </FeatureHubContextProvider>,
    featureApp,
  );
}

async function renderFeatureApp(
  featureApp: Element,
  featureAppAttributes: FeatureAppAttributes,
  featureAppManager: FeatureAppManager,
): Promise<void> {
  const { baseUrl, config, css, featureAppId, serverSrc, src } =
    featureAppAttributes;

  const { ReactDom } = await import(
    /* webpackChunkName: "react-dom" */ '../externals/react-dom'
  );

  ReactDom.render(
    <FeatureHubContextProvider value={{ featureAppManager }}>
      <FeatureAppLoader
        {...(baseUrl && { baseUrl: baseUrl })}
        {...(config && { config: config })}
        {...(css && { css: css })}
        {...(featureAppId && { featureAppId: featureAppId })}
        {...(serverSrc && { serverSrc: serverSrc })}
        {...(src && { src: src })}
        beforeCreate={beforeCreateInitTracking}
      />
    </FeatureHubContextProvider>,
    featureApp,
  );
}

export {
  hydrateFeatureApp,
  renderFeatureApp,
  beforeCreateInitTracking as __beforeCreateInitTracking,
};
