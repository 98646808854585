import * as history from 'history';
import {
  RootLocationDescriptorObject,
  RootLocationTransformer,
  createRootLocationForOtherConsumer,
  createRootLocationForPrimaryConsumer,
  createSearchParams,
  getConsumerPath,
  serializeSearchParams,
} from '@feature-hub/history-service';

import { getNavigationOptions } from './service-configuration';

const consumerPathsQueryParamName = 'consumerLocations';

function getHashLocation(
  rootLocation: RootLocationDescriptorObject,
): RootLocationDescriptorObject {
  return history.parsePath(
    rootLocation.hash ? rootLocation.hash.substring(1) : '/',
  ) as RootLocationDescriptorObject;
}

/**
 * The resulting RootLocationTransformer does exactly the same as the default RootLocationTransformer except all
 * consumer locations go into the hash
 *
 * @param  primaryConsumerHistoryKeys Array of featureAppId of the primary consumer
 *                                   (aka the feature app that should have a nice and readable path)
 */
export function createRootLocationTransformer(
  integratorId: string,
  primaryConsumerHistoryKeys: string[] | undefined,
): RootLocationTransformer {
  return {
    createRootLocation: (
      currentRootLocation: RootLocationDescriptorObject,
      consumerLocation: history.LocationDescriptorObject,
      historyKey: string,
    ): history.LocationDescriptorObject => {
      if (historyKey === integratorId) {
        return {
          ...consumerLocation,
          hash: currentRootLocation.hash,
        };
      }
      const consumerRootLocation = getHashLocation(currentRootLocation);
      const isPrimaryConsumer =
        primaryConsumerHistoryKeys.indexOf(historyKey) !== -1;

      let hashLocation: history.LocationDescriptorObject;
      if (isPrimaryConsumer) {
        hashLocation = createRootLocationForPrimaryConsumer(
          consumerRootLocation,
          consumerLocation,
          consumerPathsQueryParamName,
        );
      } else {
        hashLocation = createRootLocationForOtherConsumer(
          consumerRootLocation,
          consumerLocation,
          historyKey,
          consumerPathsQueryParamName,
        );
      }

      return {
        ...currentRootLocation,
        hash: `#${history.createPath(hashLocation)}`,
      };
    },

    getConsumerPathFromRootLocation: (
      rootLocation: RootLocationDescriptorObject,
      historyKey: string,
    ): string | undefined => {
      if (historyKey === integratorId) {
        return history.createPath({
          ...rootLocation,
          hash: undefined,
        });
      }

      rootLocation = getHashLocation(rootLocation);

      const isPrimaryConsumer =
        primaryConsumerHistoryKeys.indexOf(historyKey) !== -1;
      const searchParams = createSearchParams(rootLocation);

      if (isPrimaryConsumer) {
        searchParams.delete(consumerPathsQueryParamName);

        const { pathname, hash } = rootLocation;
        const search = serializeSearchParams(searchParams);

        return history.createPath({
          hash,
          pathname,
          search,
        });
      }

      const consumerPaths = searchParams.get(consumerPathsQueryParamName);

      if (!consumerPaths) {
        return undefined;
      }

      return getConsumerPath(consumerPaths, historyKey);
    },
  };
}

const navigationServiceOptions = getNavigationOptions();

let productListFAId = '';
let productDetailsFAId = '';
let cartFAId = '';
let checkoutFAId = '';

if (navigationServiceOptions.pages) {
  if (
    navigationServiceOptions.pages['product-list'] &&
    navigationServiceOptions.pages['product-list'].featureApps &&
    navigationServiceOptions.pages['product-list'].featureApps.details
  ) {
    productListFAId =
      navigationServiceOptions.pages['product-list'].featureApps.details;
  }
  if (
    navigationServiceOptions.pages['product-details'] &&
    navigationServiceOptions.pages['product-details'].featureApps &&
    navigationServiceOptions.pages['product-details'].featureApps.details
  ) {
    productDetailsFAId =
      navigationServiceOptions.pages['product-details'].featureApps.details;
  }
  if (
    navigationServiceOptions.pages['cart'] &&
    navigationServiceOptions.pages['cart'].featureApps &&
    navigationServiceOptions.pages['cart'].featureApps.details
  ) {
    cartFAId = navigationServiceOptions.pages['cart'].featureApps.details;
  }
  if (
    navigationServiceOptions.pages['checkout'] &&
    navigationServiceOptions.pages['checkout'].featureApps &&
    navigationServiceOptions.pages['checkout'].featureApps.details
  ) {
    checkoutFAId =
      navigationServiceOptions.pages['checkout'].featureApps.details ?? '';
  }
}

const rootLocationTransformer = createRootLocationTransformer(
  'audi:integrator:csr', // integrator id
  [productListFAId, productDetailsFAId, cartFAId, checkoutFAId], // primaryconsumer id's
);

export { rootLocationTransformer };
