import { serializedStateManagerDefinition } from '@feature-hub/serialized-state-manager';

const featureServiceDependencies = {
  ['audi-content-service']: '^1.0.0',
  [serializedStateManagerDefinition.id]: '^1.0.0',
  ['s2:history']: '^2.0.0',
  ['vw:authService']: '^2.0.0',
};

export { featureServiceDependencies };
