declare let __webpack_public_path__: string;

const integratorArtifactName = 'audi-feature-hub-integrator-csr.js';

const calculatePathFromLocation = (
  currentLocation: string,
  integratorSrc: string,
): string => {
  const currentPath = currentLocation.substring(
    0,
    currentLocation.lastIndexOf('/'),
  );

  if (integratorSrc.indexOf('.') !== -1) {
    const directoryLevels = integratorSrc.split('..').length - 1;
    if (directoryLevels > 0) {
      const pathParts = currentPath.split('/');
      if (pathParts.length > directoryLevels + 2) {
        pathParts.splice(-directoryLevels, directoryLevels);
        const shortenedPath = pathParts.join('/');
        return shortenedPath + '/';
      }
    }
  }
  return currentPath.endsWith('/') ? currentPath : currentPath + '/';
};

const calculateWebpackPublicPath = (integratorScriptTag: Element): string => {
  if (integratorScriptTag) {
    const integratorLocation = integratorScriptTag.getAttribute('src');

    if (integratorLocation) {
      const integratorPath = integratorLocation.substring(
        0,
        integratorLocation.lastIndexOf(integratorArtifactName),
      );

      if (integratorPath && !integratorPath.startsWith('.')) {
        return integratorPath;
      } else {
        return calculatePathFromLocation(
          window.location.href,
          integratorLocation,
        );
      }
    }
  }

  return '/';
};

const setWebpackPublicPath = (): void => {
  const integratorScriptTag = document.querySelector(
    '[src$="' + integratorArtifactName + '"]',
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, prefer-const
  __webpack_public_path__ = calculateWebpackPublicPath(integratorScriptTag);
};

export {
  setWebpackPublicPath,
  calculatePathFromLocation as __calculatePathFromLocation,
  calculateWebpackPublicPath as __calculateWebpackPublicPath,
};
