import { FeatureServiceProviderDefinition, Logger } from '@feature-hub/core';
import { featureServiceRegistry } from './service-registry';
import { loadAmdModule } from '@feature-hub/module-loader-amd';

function executeDefineFunction(
  functionName: string,
  context: unknown,
  config?: Record<string, unknown>,
): (string?) => void {
  const namespaces = functionName.split('.');
  const functionCall = namespaces.pop();
  if (context) {
    for (let i = 0; i < namespaces.length; i++) {
      context = context[namespaces[i]];
    }
    if (typeof context[functionCall] === 'function') {
      if (typeof config !== 'undefined') {
        return context[functionCall](config);
      }
      return context[functionCall]();
    } else if (context && context[functionCall]) {
      return context[functionCall];
    }
  }
  return;
}

async function loadFeatureServiceBundle(
  bundleKey: string,
  bundleId: string,
  bundleSource: string,
  context: unknown,
  logger: Logger,
): Promise<void> {
  if (!context[bundleId] || context[bundleKey] != bundleSource) {
    try {
      const moduleLoadResult = await loadAmdModule(bundleSource);
      if (moduleLoadResult) {
        context[bundleId] = moduleLoadResult;
        context[bundleKey] = bundleSource;
      }
    } catch (error) {
      logger.error(
        `[feature service loading] error loading ${bundleId} from ${bundleSource}: ${error.message}`,
      );
    }
  }
}

async function loadFeatureServices(
  dom: Element,
  context: unknown,
  logger: Logger,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Array<FeatureServiceProviderDefinition<any>>> {
  const featureServicesElements = dom.querySelectorAll('feature-service');
  const featureServicesMap = new Map();
  [...featureServicesElements].map(async (featureServiceBundle) => {
    // from version 6 onwards feature-service definitions from DOM are deprecated.
    // featureServicesMap.set(
    //   featureServiceBundle.id,
    //   featureServiceBundle.getAttribute('src'),
    // );
    logger.warn(
      `Feature-Service definition from HTML-DOM are deprecated after version 6. Found ${
        featureServiceBundle.id
      } with version ${featureServiceBundle.getAttribute('src')}`,
    );
  });
  const serviceDefinitionsToLoad = [];
  const registeredFeatureServices = Object.keys(featureServiceRegistry);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (context as any)['global'] = context;

  await Promise.all(
    registeredFeatureServices.map(async (featureServiceBundleId) => {
      if (
        featureServicesMap.has(featureServiceBundleId) ||
        featureServiceRegistry[featureServiceBundleId].fallbackSrc
      ) {
        const featureServiceSource =
          featureServicesMap.get(featureServiceBundleId) ||
          featureServiceRegistry[featureServiceBundleId].fallbackSrc;

        const serviceBundleKey = `${featureServiceBundleId}-key`;

        await loadFeatureServiceBundle(
          serviceBundleKey,
          featureServiceBundleId,
          featureServiceSource,
          context,
          logger,
        );

        // iterate through feature services
        featureServiceRegistry[featureServiceBundleId].services.forEach(
          (featureServiceDefinition) => {
            const defineFunctionName = featureServiceDefinition.define;
            const featureServiceId = featureServiceDefinition.id;

            if (context[featureServiceBundleId][defineFunctionName]) {
              const optionGetter = featureServiceDefinition.optionGetter;
              let param;

              if (optionGetter) {
                param = optionGetter(document.head);
              }
              const defineFunction = executeDefineFunction(
                defineFunctionName,
                context[featureServiceBundleId],
                param as unknown as Record<string, unknown>,
              );

              if (defineFunction && defineFunction['id'] === featureServiceId) {
                serviceDefinitionsToLoad.push(defineFunction);
              } else if (Array.isArray(defineFunction)) {
                defineFunction.forEach((groupedDefineFunction) => {
                  if (groupedDefineFunction && groupedDefineFunction['id']) {
                    serviceDefinitionsToLoad.push(groupedDefineFunction);
                  }
                });
              } else {
                logger.error(`loading of ${featureServiceId} failed`);
              }
            }
          },
        );
      }
    }),
  );
  return serviceDefinitionsToLoad;
}

export {
  executeDefineFunction as __executeDefineFunction,
  loadFeatureServiceBundle as __loadFeatureServiceBundle,
  loadFeatureServices,
};
