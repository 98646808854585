import {
  getColaOptions,
  getConfiguratorLinkOptions,
  getCurrentCarlineOptions,
  getDisclaimerManagerOptions,
  getGfaServiceConfig,
  getI18nOptions,
  getMarketContextServiceOptions,
  getNavigationOptions,
  getRenderModeOptions,
  getTrackingOptions,
  getVueFormatterOptions,
} from './service-configuration';

const audiServicesVersion = process.env.SERVICE_VERSION as string;
const colaServiceVersion = process.env.SERVICE_COLA_VERSION as string;
const footnoteReferenceVersion = process.env
  .SERVICE_FOOTNOTE_REFERENCE_VERSION as string;
const vueFormatterVersion = process.env.SERVICE_VUE_FORMATTER_VERSION as string;

interface FeatureServiceDefinition {
  define: string;
  id: string;
  optionGetter?: (string) => unknown;
}
interface FeatureServiceLoadingInterface {
  fallbackSrc?: string;
  services: Array<FeatureServiceDefinition>;
}

export const featureServiceRegistry: Record<
  string,
  FeatureServiceLoadingInterface
> = {
  'audi-cola-service': {
    fallbackSrc: `https://assets.audi.com/audi-cola-service/${colaServiceVersion}/audi-cola-service.umd.js`,
    services: [
      {
        define: 'defineAudiColaService',
        id: 'audi-cola-service',
        optionGetter: getColaOptions,
      },
    ],
  },
  'audi-feature-services': {
    fallbackSrc: `https://assets.audi.com/audi-feature-services/${audiServicesVersion}/audi-feature-services.umd.js`,
    services: [
      {
        define: 'defineAudiAudicodeService',
        id: 'audi-audicode-service',
      },
      {
        define: 'defineAudiColaQueryService',
        id: 'audi-cola-query-service',
      },
      {
        define: 'defineAudiHeaderStateService',
        id: 'audi-header-state-service',
      },
      {
        define: 'defineConfiguratorDataService',
        id: 'audi-configurator-data-service',
      },
      {
        define: 'defineAudiConfiguratorFootnoteService',
        id: 'audi-configurator-footnote-service',
      },
      {
        define: 'defineAudiConfiguratorLinkService',
        id: 'audi-configurator-link-service',
        optionGetter: getConfiguratorLinkOptions,
      },
      {
        define: 'defineCurrentCarlineService',
        id: 'audi-current-carline-service',
        optionGetter: getCurrentCarlineOptions,
      },
      {
        define: 'defineAudiFootnoteService',
        id: 'audi-footnote-service',
      },
      {
        define: 'defineI18nService',
        id: 'dbad:audi-i18n-service',
        optionGetter: getI18nOptions,
      },
      {
        define: 'defineAudiMarketContextService',
        id: 'audi-market-context-service',
        optionGetter: getMarketContextServiceOptions,
      },
      {
        define: 'defineNotificationDisplayService',
        id: 'notification-display-service',
      },
      {
        define: 'definePersonalizationService',
        id: 'audi-personalization-service',
      },
      {
        define: 'defineTrackingService',
        id: 'audi-tracking-service',
        optionGetter: getTrackingOptions,
      },
      {
        define: 'defineAudiVideoService',
        id: 'audi-video-service',
      },
      {
        define: 'defineDealerSearchService',
        id: 'dealer-search-service',
      },
      {
        define: 'defineDisclaimerManagers',
        id: 'disclaimer-manager',
        optionGetter: getDisclaimerManagerOptions,
      },
      {
        define: 'defineNavigationService',
        id: 'navigation-service',
        optionGetter: getNavigationOptions,
      },
      {
        define: 'defineGfaNavigationService',
        id: 'gfa:navigation-service',
      },
      {
        define: 'defineRenderModeService',
        id: 'audi-render-mode-service',
        optionGetter: getRenderModeOptions,
      },
      {
        define: 'defineEventService',
        id: 'event-service',
      },
      {
        define: 'layerManagerDefinition',
        id: 'layer-manager',
      },
      {
        define: 'defineGfaServiceConfigProvider',
        id: 'gfa:service-config-provider',
        optionGetter: getGfaServiceConfig,
      },
      {
        define: 'gfaLayerManagerDefinition',
        id: 'gfa:layer-manager',
      },
    ],
  },
  'audi-footnote-reference-service': {
    fallbackSrc: `https://assets.audi.com/audi-footnote-reference-service/${footnoteReferenceVersion}/audi-footnote-reference-service.umd.js`,
    services: [
      {
        define: 'defineAudiFootnoteReferenceService',
        id: 'audi-footnote-reference-service',
      },
    ],
  },
  'audi-vue-formatter-service': {
    fallbackSrc: `https://assets.audi.com/audi-vue-formatter-service/${vueFormatterVersion}/audi-vue-formatter-service.umd.js`,
    services: [
      {
        define: 'defineVueFormatterService',
        id: 'dbad:audi-vue-formatter-service',
        optionGetter: getVueFormatterOptions,
      },
    ],
  },
};
