import {
  ContentStore,
  defineContentService,
} from '@volkswagen-onehub/audi-content-service';
import {
  FeatureServiceProviderDefinition,
  FeatureServices,
  Logger,
  SharedFeatureService,
} from '@feature-hub/core';
import {
  defineAuthService,
  defineGfaAuthService,
} from '@volkswagen-onehub/audi-auth-service';
import {
  defineGfaLocalService,
  defineLocaleService,
} from '@volkswagen-onehub/locale-service';
import {
  getAuthOptions,
  getCarlineLinkServiceConfig,
  getCookieServiceOption,
  getEnvConfigOptions,
  getGfaServiceConfig,
  getLocaleOptions,
  prepareWindow,
} from './service-configuration';
import { defineCarlineLinkService } from '@volkswagen-onehub/audi-carline-link-service';
import { defineCookieService } from '@volkswagen-onehub/audi-cookie-service';
import { defineEnvConfigService } from '@volkswagen-onehub/audi-env-config-service/dist/EnvConfigService';
import { defineGfaServiceConfigProvider } from '@volkswagen-onehub/audi-gfa-service-config-provider';
import { defineHistoryService } from '@feature-hub/history-service';
import { defineLogger } from '@feature-hub/logger';
import { defineNotificationDisplayService } from '@volkswagen-onehub/audi-notification-display-service';
import { defineUserVehicleFeatureService } from '@oneaudi/user-vehicle-feature-service';
import { loadFeatureServices } from './service-loading';
import { pubSubFeatureService } from '@volkswagen-onehub/audi-feature-service-pubsub';
import { rootLocationTransformer } from './service-configuration-history';
import { serializedStateManagerDefinition } from '@feature-hub/serialized-state-manager';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  interface Window {
    SETUPS: {
      get(string): string;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    featureServiceDefinitions: Array<FeatureServiceProviderDefinition<any>>;
    digitalData: Record<string, unknown>;
  }
}

const featureServiceDefinitions = async (
  contentStore: ContentStore,
  logger: Logger,
): Promise<
  FeatureServiceProviderDefinition<SharedFeatureService, FeatureServices>[]
> => {
  prepareWindow();
  const markupServiceDefinitions = await loadFeatureServices(
    document.body,
    window,
    logger,
  );

  const serviceDefinitions = [
    defineHistoryService(rootLocationTransformer),
    defineLogger(),
    defineAuthService(getAuthOptions(document.head)),
    defineGfaAuthService(),
    defineCarlineLinkService(getCarlineLinkServiceConfig(document.head)),
    defineCookieService(getCookieServiceOption()),
    defineContentService(contentStore),
    defineNotificationDisplayService(),
    defineEnvConfigService(getEnvConfigOptions(document.head)),
    defineGfaServiceConfigProvider(getGfaServiceConfig()),
    defineLocaleService(getLocaleOptions(document.head)),
    defineGfaLocalService(),
    defineUserVehicleFeatureService(),
    pubSubFeatureService,
    serializedStateManagerDefinition,
    ...markupServiceDefinitions,
    ...window.featureServiceDefinitions,
  ];

  return serviceDefinitions;
};

export { featureServiceDefinitions };
