import {
  IAuthServiceV2,
  ILoginOptionsV2,
} from '@volkswagen-onehub/audi-auth-service/dist/types/v2/AuthServiceV2';

let authService: IAuthServiceV2;

const getUserIdFromIdToken = (idToken: string): string => {
  let userId = '';
  if (idToken && idToken.indexOf('.') !== -1) {
    const idObject = JSON.parse(
      Buffer.from(idToken.split('.')[1], 'base64').toString('utf-8'),
    );
    userId = idObject && idObject.sub ? idObject.sub : userId;
  }
  return userId;
};

interface authUserData {
  authToken: string;
  idToken: string;
  isAuthenticated: boolean;
  userId: string;
}

const getAuthUserData = async (
  authService_: IAuthServiceV2,
): Promise<authUserData> => {
  const isAuthenticated = await authService_.isAuthenticated();
  let authToken = '';
  let idToken = '';
  let userId = '';

  if (isAuthenticated) {
    authToken = await authService_.getAccessToken();
    idToken = await authService_.getIdToken();
    userId = getUserIdFromIdToken(idToken);
  }
  return {
    authToken,
    idToken,
    isAuthenticated,
    userId,
  };
};

const loginAction = (
  state: unknown,
  {
    prompt = '',
    redirectUrl = location.href,
    options = {},
  }: {
    prompt?: string;
    redirectUrl?: string;
    options?: ILoginOptionsV2;
  },
): void => {
  authService.login(prompt, redirectUrl, options);
};

const logoutAction = (
  state: unknown,
  {
    redirectUrl = location.href,
  }: {
    redirectUrl?: string;
  },
): void => {
  authService.logout(redirectUrl);
};

const getAccessToken = async (
  state: Record<string, unknown>,
): Promise<Record<string, unknown>> => {
  const authToken = await authService.getAccessToken();
  return {
    ...state,
    authToken,
  };
};

const getIdToken = async (
  state: Record<string, unknown>,
): Promise<Record<string, unknown>> => {
  const idToken = await authService.getIdToken();
  return {
    ...state,
    idToken,
  };
};

const processAuthService = async (
  authService_: IAuthServiceV2,
): Promise<void> => {
  authService = authService_;
  if (window.microkernel) {
    const { authToken, idToken, isAuthenticated, userId } =
      await getAuthUserData(authService);

    window.microkernel.stateRegistry.addStore(
      'dbadAuthServiceStore',
      {
        authToken,
        idToken,
        isAuthenticated,
        userId,
      },
      {
        getAccessToken,
        loginAction,
        logoutAction,
      },
    );
  }
};

export {
  processAuthService,
  getAccessToken as __getAccessToken,
  getAuthUserData as __getAuthUserData,
  getIdToken as __getIdToken,
  getUserIdFromIdToken as __getUserIdFromIdToken,
  loginAction as __loginAction,
  logoutAction as __logoutAction,
};
