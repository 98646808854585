import { ContentStore } from '@volkswagen-onehub/audi-content-service';
import { FeatureAppManager } from '@feature-hub/core';
import { createLogger } from '../logger';

import { processFeatureApp } from './feature-app-processing';

class FeatureAppIntegrator {
  static FEATURE_APP_STATUS_NEW = 'new';
  static FEATURE_APP_STATUS_MOUNTED = 'mounted';

  featureAppManager: FeatureAppManager;
  contentStore: ContentStore;
  logger = createLogger();

  /** Observe viewport changes */
  intersectionObserver: IntersectionObserver;

  /** Observe dome changes */
  mutationObserver: MutationObserver;

  /** Remember feature apps and their rendering state on page */
  featureAppMap: Map<Element, string> = new Map<Element, string>();

  constructor(
    featureAppManager: FeatureAppManager,
    contentStore: ContentStore,
  ) {
    this.featureAppManager = featureAppManager;
    this.contentStore = contentStore;

    const intersectionObserverOptions = {
      rootMargin: '100% 0px 100% 0px', // trigger intersection on feature apps that are placed ~1 viewport above or below
    };

    if ('IntersectionObserver' in window) {
      this.intersectionObserver = new IntersectionObserver(
        this.__handleIntersection.bind(this),
        intersectionObserverOptions,
      );
    }

    this.mutationObserver = new MutationObserver(
      this.__handleMutation.bind(this),
    );
  }

  /** Initialize feature apps in a given dom element
      and it's children. Does not initialize same feature
      app twice if it already has been initalized. */
  async initFeatureApps(dom: HTMLElement): Promise<void> {
    const apps = Array.from(dom.querySelectorAll('feature-app[src]'));

    const filterInvalidFeatureApps = (featureAppNode: Element): boolean => {
      if (this.featureAppMap.has(featureAppNode)) return false;

      const isEmptySrc = featureAppNode.getAttribute('src') === '';

      if (isEmptySrc) {
        this.logger.info(
          `Feature app "${featureAppNode.getAttribute(
            'id',
          )}" does not have "src" attribute defined, skipping CSR`,
        );
      }

      return !isEmptySrc;
    };

    await Promise.all(
      apps.filter(filterInvalidFeatureApps).map(async (featureAppNode) => {
        this.featureAppMap.set(
          featureAppNode,
          FeatureAppIntegrator.FEATURE_APP_STATUS_NEW,
        );

        // Only IF we have an intersectionObserver AND the loading is set to lazy we DO an observe,
        // direct mounting of app otherwise
        const loading = featureAppNode.getAttribute('loading');
        if (
          this.intersectionObserver &&
          loading &&
          loading.toLowerCase() == 'lazy'
        ) {
          this.intersectionObserver.observe(featureAppNode);
        } else {
          await this.__mountFeatureApp(featureAppNode);
        }

        this.__observeMutationsRecursively(featureAppNode);

        this.logger.info('Initialised Feature App ' + featureAppNode.id + '.');
      }),
    );
  }

  __isAbsoluteUrl(url: string): boolean {
    return url.includes('//');
  }

  async preloadFeatureApp(url: string, baseUrl: string): Promise<void> {
    try {
      let fullUrl = url;
      if (baseUrl && !this.__isAbsoluteUrl(url)) {
        if (baseUrl.endsWith('/') || url.startsWith('/')) {
          fullUrl = baseUrl + url;
        } else {
          fullUrl = baseUrl + '/' + url;
        }
      }
      return this.featureAppManager.preloadFeatureApp(fullUrl);
    } catch (error) {
      this.logger.warn(`preloading error (${url}): ${error}`);
      return Promise.resolve();
    }
  }

  __handleIntersection(entries: IntersectionObserverEntry[]): void {
    entries.forEach((intersectionEntry: IntersectionObserverEntry) => {
      // Return if not intersecting…
      if (!intersectionEntry.isIntersecting) return;

      this.__mountFeatureApp(intersectionEntry.target);
    });
  }

  __handleMutation(mutations: Array<MutationRecord>): void {
    this.logger.info(`Mutation observer found ${mutations.length} records`);

    mutations.forEach((mutation) => {
      mutation.removedNodes.forEach((removedNode) => {
        for (const featureAppNode of this.featureAppMap.keys()) {
          if (removedNode.contains(featureAppNode)) {
            this.__unmountComponentAtNode(featureAppNode);
          }
        }
      });
    });
  }

  async __mountFeatureApp(featureAppNode: Element): Promise<void> {
    const status = this.featureAppMap.get(featureAppNode);

    if (status == FeatureAppIntegrator.FEATURE_APP_STATUS_NEW) {
      const featureAppUrl = featureAppNode.getAttribute('src');
      const featureAppBaseUrl = featureAppNode.getAttribute('base-url');

      await this.preloadFeatureApp(featureAppUrl, featureAppBaseUrl);

      this.featureAppMap.set(
        featureAppNode,
        FeatureAppIntegrator.FEATURE_APP_STATUS_MOUNTED,
      );

      processFeatureApp(
        featureAppNode,
        this.featureAppManager,
        this.contentStore,
        this.logger,
      );

      this.logger.info('Mounted Feature App ' + featureAppNode.id + '.');
    }
  }

  /** Unmount a feature app at a DOM node from page. */
  async __unmountComponentAtNode(featureAppNode: Element): Promise<void> {
    const status = this.featureAppMap.get(featureAppNode);

    // Real un-mount only on mounted feature apps.
    if (status == FeatureAppIntegrator.FEATURE_APP_STATUS_MOUNTED) {
      const { ReactDom } = await import(
        /* webpackChunkName: "react-dom" */ '../externals/react-dom'
      );
      ReactDom.unmountComponentAtNode(featureAppNode);
    }

    // cleanup some more things
    this.featureAppMap.delete(featureAppNode);
    this.intersectionObserver.unobserve(featureAppNode);

    this.logger.info('Unmounted Feature App ' + featureAppNode.id + '.');
  }

  __observeMutationsRecursively(element: Node): void {
    // break condition
    if (element.parentNode === document.body.parentNode) {
      return;
    }

    this.mutationObserver.observe(element.parentNode, {
      attributes: false,
      characterData: false,
      childList: true,
    });

    this.__observeMutationsRecursively(element.parentNode);
  }
}

export { FeatureAppIntegrator };
