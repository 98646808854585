import { Logger } from '@feature-hub/logger';

function isDebugMode(searchParamString: string): boolean {
  const searchParams = new URLSearchParams(searchParamString);
  const debugMode = searchParams.get('debug');
  return debugMode && debugMode === 'true' ? true : false;
}

function createLogger(): Logger {
  function info(message?: unknown): void {
    if (
      process.env.NODE_ENV !== 'production' ||
      isDebugMode(window.location.search) ||
      isDebugMode(window.location.hash.slice(1))
    ) {
      console.info(message);
    }
  }

  function warn(message?: unknown): void {
    if (
      process.env.NODE_ENV !== 'production' ||
      isDebugMode(window.location.search) ||
      isDebugMode(window.location.hash.slice(1))
    ) {
      console.warn(message);
    }
  }

  function error(message?: unknown): void {
    console.error(message);
  }

  return {
    debug: info,
    error: error,
    info: info,
    trace: info,
    warn: warn,
  };
}

export { createLogger, isDebugMode as __isDebugMode };
