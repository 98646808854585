import { audiUiReact, audiUiReactV2 } from '../externals/audi-ui-react';
// eslint-disable-next-line sort-imports
import * as FeatureHubReact from '@feature-hub/react';
import * as ModuleLoaderAmd from '@feature-hub/module-loader-amd';
import * as React from 'react';

const definedExternals = async (): Promise<ModuleLoaderAmd.Externals> => {
  const [{ axios }, { ReactDom }, { onegraphClient }, { styled }] =
    await Promise.all([
      import(/* webpackChunkName: "axios" */ '../externals/axios'),
      import(/* webpackChunkName: "react-dom" */ '../externals/react-dom'),
      import(
        /* webpackChunkName: "volkswagen-onehub-onegraph-client" */ '../externals/volkswagen-onehub-onegraph-client'
      ),
      import(
        /* webpackChunkName: "styled-components" */ '../externals/styled-components'
      ),
    ]);

  return {
    '@audi/audi-ui-react': audiUiReact,
    '@audi/audi-ui-react-v2': audiUiReactV2,
    '@feature-hub/module-loader-amd': ModuleLoaderAmd,
    '@feature-hub/react': FeatureHubReact,
    '@volkswagen-onehub/onegraph-client': onegraphClient,
    axios,
    react: React,
    'react-dom': ReactDom,
    'styled-components': styled,
  };
};

const providedExternals = {
  '@audi/audi-ui-react': process.env.AUDI_UI_REACT_VERSION as string,
  '@audi/audi-ui-react-v2': process.env.AUDI_UI_REACT_V2_VERSION as string,
  '@feature-hub/module-loader-amd': process.env.FEATURE_HUB_VERSION as string,
  '@feature-hub/react': process.env.FEATURE_HUB_VERSION as string,
  '@volkswagen-onehub/onegraph-client': process.env
    .ONEGRAPH_CLIENT_VERSION as string,
  axios: process.env.AXIOS_VERSION as string,
  react: process.env.REACT_VERSION as string,
  'react-dom': process.env.REACT_DOM_VERSION as string,
  'styled-components': process.env.STYLED_COMPONENTS_VERSION as string,
};

export { definedExternals, providedExternals };
